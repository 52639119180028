<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-card outlined>
        <v-toolbar flat>
          <v-list-item class="pl-0 pr-0">
            <v-list-item-avatar :color="user.status === 1 ? 'accent' : 'grey'">
              <img
                v-if="user.avatar"
                :src="getAvatar(user.avatar)"
                :alt="user.full_name"
              />
              <span v-else class="white--text">{{ user.initials }}</span>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ user.full_name }}</v-list-item-title>
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <template v-slot:extension>
            <v-tabs>
              <v-tab :to="{ name: 'app-users-profile' }">
                <v-icon left>mdi-account-details</v-icon>Profile Details
              </v-tab>
              <v-tab :to="{ name: 'app-users-profile-user-access' }">
                <v-icon left>mdi-key</v-icon>User Access
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
        <v-divider></v-divider>
        <div class="pa-5">
          <router-view v-bind:user="user"></router-view>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Users",
          disabled: false,
          exact: true,
          to: { name: "app-users", params: { id: this.$route.params.id } },
        },
      ],
    };
  },

  methods: {
    getAvatar: function (avatarUrl) {
      return `${process.env.VUE_APP_ACCOUNTS_URL}/storage/${avatarUrl}`;
    },
  },
};
</script>
